import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Container, Tab, Typography } from "@mui/material"
import { makeId } from "lib/make-id"
import { useReference } from "lib/@hooks/use-reference"
import { useEvent } from "lib/@hooks/useEvent"
import { handleNamed } from "library/local-events"
import { useLayoutEffect } from "react"
import { frag } from "lib/@components/slot/frag"
import { useSlot } from "lib/@components/slot/use-slot"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { checkPermission } from "library/authorization"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { usePropsObject } from "lib/@hooks/use-props-object"
import { Bound } from "lib/@components/binding/Bound"
import { RefreshSlot } from "event-definitions"

export function Tabs({ id, searchParam, tabHolder = <Container />, panelHolder = frag }) {
    const refresh = useRefresh(noChange).debounced
    const tabs = useSlot(`${id}-Tab`, frag)
    const panels = useSlot(`${id}-Panel`)
    const refreshers = useSlot(`${id}-Refresh`, undefined, usePropsObject({ refresh }))
    const tabKey = `${id}Tab`
    const [reference, save] = useReference({ [tabKey]: null }, `tab-${id}-`)

    let [currentTab, setTab] = useSearchParam(tabKey, "")
    if (!searchParam) {
        currentTab = reference[tabKey]
    }
    if (tabs.length && !tabs.some((tab) => tab.props.value === currentTab)) {
        currentTab = tabs[0].props.value
    }

    return (
        !!tabs.length && (
            <Bound currentTab={`${tabKey}=${currentTab}`}>
                <TabContext value={currentTab}>
                    {refreshers}
                    {
                        <tabHolder.type {...tabHolder.props}>
                            <TabList
                                sx={{
                                    "& .MuiTabScrollButton-root": {
                                        position: "relative",
                                        top: 12,
                                        width: 24,
                                        height: 24,
                                    },
                                }}
                                TabScrollButtonProps={{ sx: { width: 24, height: 48 } }}
                                variant="scrollable"
                                className="tabs"
                                onChange={(_, tab) => {
                                    setTab(tab)
                                    save({ [tabKey]: tab })
                                }}
                            >
                                {tabs}
                            </TabList>
                        </tabHolder.type>
                    }
                    <panelHolder.type {...panelHolder.props}>{panels}</panelHolder.type>
                </TabContext>
            </Bound>
        )
    )
}

export function PDFTabs({ id }) {
    const tabs = useSlot(`${id}-Tab`, frag)
    const panels = useSlot(`${id}-Panel`)
    const tabKey = `${id}Tab`
    const [reference] = useReference({ [tabKey]: null }, `tab-${id}`)
    if (tabs.length && !tabs.some((tab) => tab.props.value === reference[tabKey])) {
        reference[tabKey] = tabs[0].props.value
    }

    return (
        !!tabs.length &&
        tabs.map(
            (tab) =>
                tab.props.value !== "Guidance" &&
                tab.props.value !== "Codes" && (
                    <Box key={tab.props.value} mb={2} className="keep-together">
                        <Typography variant="h5" gutterBottom>
                            {tab.props.label}
                        </Typography>
                        {panels.find((p) => p.props.value === tab.props.value).props.children}
                    </Box>
                )
        )
    )
}

export function useTab({ tab, id, title = id, content }) {
    useEvent(
        `Slot.${tab}-Tab`,
        ({ add }) =>
            !!title && add(<Tab priority={content.props.priority ?? 100} disableRipple value={id} label={title} />),
        [title]
    )
    useEvent(`Slot.${tab}-Panel`, ({ add }) => !!content && add(<TabPanel value={`${id}`}>{content}</TabPanel>), [
        content,
    ])
    useLayoutEffect(() => {
        RefreshSlot(`${tab}-Tab`).raiseOnce()
        RefreshSlot(`${tab}-Panel`).raiseOnce()
    }, [tab, title, content])
}

export function registerTab({
    tab,
    demands,
    matchAllDemands = true,
    id,
    title = id,
    content,
    refresher,
    tabProps,
    predicate = () => true,
}) {
    handleNamed(`${id}-${title}-tab`, `Slot.${tab}-Tab`, ({ add, context }) => {
        const shouldRender = demands
            ? checkPermission(demands, matchAllDemands) && predicate(context)
            : predicate(context)

        return (
            shouldRender &&
            add(
                <Tab
                    {...tabProps}
                    className={`tab tab-${id}`}
                    data-cy={`${makeId(id)}-${makeId(tab)}-tab`}
                    priority={content.props.priority ?? 100}
                    disableRipple
                    sx={{ ...content.props.sx }}
                    value={id}
                    label={title}
                />
            )
        )
    })
    handleNamed(`${id}-${title}-panel`, `Slot.${tab}-Panel`, ({ add, context }) => {
        const shouldRender = demands
            ? checkPermission(demands, matchAllDemands) && predicate(context)
            : predicate(context)

        return (
            shouldRender &&
            add(
                <TabPanel sx={{ flex: 1, display: "flex", flexDirection: "column" }} value={id}>
                    {content}
                </TabPanel>
            )
        )
    })
    handleNamed(`${id}-${title}-refresh`, `Slot.${tab}-Refresh`, ({ add }) => !!refresher && add(refresher))
}
